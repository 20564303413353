<script context="module" lang="ts">
	import cn from 'classnames';
	import type { ICartLine } from '../../stores/preferences';
	import success from '$lib/images/kosik/success-basket-added.svg';

	let onTop: any; //keeping track of which open modal is on top
	const modals: any = ['cart']; //all modals get registered here for easy future access
	let productName = '';
	let productQty = 0;
	let productModel: any;
	let hash_link = '';

	// 	returns an object for the modal specified by `id`, which contains the API functions (`open` and `close` )
	export function getModal(_hash_link: string) {
		productModel = null;
		hash_link = _hash_link;
		productName = '';
		productQty =  0;
		return modals[''];
	}
</script>

<script lang="ts">
	import { onDestroy } from 'svelte';
	import Button from '../(components)/Button.svelte';
	import Close from '$lib/images/svg/close.svelte';
	import { PUBLIC_CART_SERVICE_URL } from '$env/static/public';
	import { generateSignature } from '$lib/utils/Signature';

	let topDiv: any;
	let visible = false;
	let prevOnTop: any;
	let closeCallback: any;
	let prodName: string;
	let productQuantity: number;
	let product: any;

	export let id = '';

	function keyPress(ev: any) {
		if (ev.key == 'Escape' && onTop == topDiv) console.log('close');
		//close()
	}

	async function stornoOrder() {

		let signature = await generateSignature();
		const settings = {
			method: 'GET',
			headers: signature
		};

		const responseFromApi = await fetch(
			PUBLIC_CART_SERVICE_URL +
			'order/' +
			hash_link +
			'/cancel',
			settings
		);
		const response_data = await responseFromApi.json();
		close(null);
	}


	/**  API **/
	function open(callback: any) {
		closeCallback = callback;
		if (visible) return;
		prevOnTop = onTop;
		onTop = topDiv;
		prodName = productName;
		productQuantity = productQty;
		product = productModel;
		document.body.style.overflow = 'hidden';

		visible = true;
		document.body.appendChild(topDiv);
	}

	function close(retVal: any) {
		if (!visible) return;
		onTop = prevOnTop;
		if (onTop == null) document.body.style.overflow = '';
		visible = false;
		if (closeCallback) closeCallback(retVal);
	}

	//expose the API
	modals[id] = { open, close };

	onDestroy(() => {
		delete modals[id];
	});
</script>

<div
	class={cn(
		'fixed left-1/2 top-1/2 z-[1000] flex max-h-[calc(100vh-1.25rem)] w-[calc(100vw-1.25rem)] max-w-[60.625rem] -translate-x-1/2 -translate-y-1/2 flex-col bg-white shadow-sm transition-transform md:shadow-custom',
		visible ? 'visible' : 'invisible'
	)}
>
	<button
		on:click={() => close(null)}
		on:keydown
		on:keypress
		on:keyup
		class="text-gray-lighter4 absolute right-0 top-0 cursor-pointer p-2 transition-transform hover:scale-125 md:p-8"
	>
		<Close class="h-4 w-4" />
	</button>

	<h1
		class="border-gray-lightest flex flex-row items-center gap-x-4 px-12 py-7 align-middle text-xl font-bold md:px-16 md:py-10 md:text-2xl mdmax:border-b-2"
	>
		<img src={success} width="45" alt="success" />Opravdu chcete objednávku stornovat?
	</h1>

	<div class="custom-vertical-scrollbar overflow-auto">
		<div class="mx-2.5 md:mx-16 md:mb-4">

			<div class="mt-4 flex flex-col gap-y-2 md:flex-row md:justify-end md:gap-x-2">
				<Button onClick={() => close(null)} isDark={true} class="py-3">Zpět</Button>
				<Button class="py-3" onClick={() => stornoOrder()}>Ano</Button>
			</div>
		</div>

		<div class="flex flex-col md:px-16 md:pb-10">
	
		</div>
	</div>
</div>

<div
	class={cn(
		'fixed bottom-0 left-0 right-0 top-0 z-[999] flex items-center justify-center bg-black bg-opacity-60',
		visible ? 'visible' : 'invisible'
	)}
	on:click={() => close()}
	on:keydown
	on:keypress
	on:keyup
/>

